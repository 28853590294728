import { AxiosResponse } from 'axios';

import { VacancyResponsesFiltersFields } from 'src/models/vacancyCreate/vacancyResponsesFilters';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        '/resumesearch/settings': {
            body: {
                field: VacancyResponsesFiltersFields[];
            };
            queryParams: void;
            response: void;
        };
    }
}

export default (
    data: FetcherPostApi['/resumesearch/settings']['body'] | FormData
): Promise<AxiosResponse<FetcherPostApi['/resumesearch/settings']['response']>> =>
    fetcher.postFormData('/resumesearch/settings', data);
