import { BrandingBuilderServiceCodes } from 'src/models/price/priceBranding/priceBranding.types';
import { UNLIMITED_RESUME_THRESHOLD } from 'src/models/price/priceZp';
import {
    BillingPlatform,
    OptionFeatures,
    ProductType,
    PublicationProductType,
    PublicationFeatures,
    ResumeAccessFeatures,
    ResumeAccessSubProductType,
    SubProductType,
} from 'src/models/price/product.types';
import { publicationsCodes, ServiceCode, ServiceItemCode } from 'src/models/price/productCodes';
import { ZpTariff } from 'src/models/price/zpTariff';

const findProduct = (
    bundledProduct: ProductType,
    predicate: (subProduct: SubProductType) => boolean
): SubProductType | undefined => Object.values(bundledProduct.products || {}).find(predicate);

export const getSubProduct = (bundledProduct: ProductType, code: ServiceItemCode): SubProductType | undefined =>
    findProduct(bundledProduct, (subProduct) => subProduct.code === code);

export const getApiCount = (product: ProductType): number | null => {
    const apiProduct = getSubProduct(product, ServiceItemCode.ApiLimited);
    return apiProduct ? parseInt(apiProduct.count, 10) : null;
};

export const getContactsCount = (product: ProductType): number | null => {
    const civProduct = getSubProduct(product, ServiceItemCode.ContactsView);
    return civProduct ? parseInt(civProduct.count, 10) : null;
};

export const isResumeAccessSubProduct = (subProduct: SubProductType): subProduct is ResumeAccessSubProductType =>
    subProduct.code === ServiceItemCode.ContactsView;

export const getResumeAccessSubProducts = (bundledProduct: ProductType): ResumeAccessSubProductType[] =>
    Object.values(bundledProduct.products || {}).filter(isResumeAccessSubProduct);

export const getResumeAccessFeatures = (product: ProductType): ResumeAccessFeatures | undefined =>
    getSubProduct(product, ServiceItemCode.ContactsView)?.features as ResumeAccessFeatures;

export const getPublicationSubProduct = (product: ProductType): SubProductType | undefined =>
    findProduct(product, ({ code }) => publicationsCodes.includes(code));

export const getPublicationFeatures = (product: ProductType): PublicationFeatures | undefined =>
    getPublicationSubProduct(product)?.features as PublicationFeatures;

export const getOptionFeatures = (product: ProductType): OptionFeatures | undefined =>
    getSubProduct(product, ServiceItemCode.OptionPremium)?.features as OptionFeatures;

export const getPublicationsCount = (product: ProductType): number | null => {
    const publicationProduct = getPublicationSubProduct(product);
    return publicationProduct ? parseInt(publicationProduct.count, 10) : null;
};

export const isResumeAccessProduct = (product: ProductType): boolean => {
    const isCivVppl = product.code === ServiceCode.ContactsViewWithStandart;
    const isCivDi = product.code === ServiceCode.Disposable && !!getSubProduct(product, ServiceItemCode.ContactsView);
    return isCivVppl || isCivDi;
};

// CIV, у которых указана определённая платформа, и только она.
export const getResumeAccessSubProductsForPlatform = (
    product: ProductType,
    platform: BillingPlatform
): ResumeAccessSubProductType[] => {
    return getResumeAccessSubProducts(product).filter((subProduct) => {
        const platforms = subProduct.features?.billingPlatforms || [];
        return platforms.length === 1 && platforms[0] === platform;
    });
};

export const getContactsCountForPlatform = (product: ProductType, platform: BillingPlatform): number | null => {
    const civProducts = getResumeAccessSubProductsForPlatform(product, platform);
    return civProducts.length ? parseInt(civProducts[0].count, 10) : null;
};

export const isPublicationsProduct = (product: ProductType): product is PublicationProductType => {
    const isNewPublicationProduct = product.code === ServiceCode.LimitedVacancy;
    const isOldPublicationProduct = publicationsCodes.includes(product.code);
    return isNewPublicationProduct || isOldPublicationProduct;
};

export const isClickmeProduct = (product: ProductType): boolean => {
    return product.code === ServiceItemCode.Clickme && !product.businessContext?.auction;
};

export const isPfpProduct = (product: ProductType): boolean => {
    return !!getSubProduct(product, ServiceItemCode.PfpBalance);
};

export const isAuctionProduct = (product: ProductType): boolean => {
    return product.code === ServiceItemCode.Clickme && Boolean(product.businessContext?.auction);
};

export const isBundleProduct = (product: ProductType): boolean => {
    return product.code === ServiceCode.Bundle;
};

export const isOptionProduct = (product: ProductType): boolean => {
    return !!getSubProduct(product, ServiceItemCode.OptionPremium);
};

export const isZpEventProduct = (product: ProductType): boolean => {
    return product.code === ServiceCode.ZpEvent;
};

export const getOptionSubProduct = (product: ProductType): SubProductType | undefined =>
    getSubProduct(product, ServiceItemCode.OptionPremium);

export const isBrandingProduct = (product: ProductType): boolean => BrandingBuilderServiceCodes.includes(product.code);

const isUnlimitedResumeTariff = (tariffName: ProductType['tariff']): boolean =>
    tariffName === ZpTariff.ResumeAccessByCategory;

export const isUnlimitedResumeAccess = (product: ProductType | null): boolean => {
    if (product?.tariff && isUnlimitedResumeTariff(product.tariff)) {
        return true;
    }
    const contactsCount = product && getContactsCount(product);
    return !!contactsCount && contactsCount >= UNLIMITED_RESUME_THRESHOLD;
};

export const hasZpCrossPost = (product: ProductType): boolean => {
    return !!getSubProduct(product, ServiceItemCode.ZpCrosspost);
};

/**
 * Возвращает код подпродукта публикации для определения типа составного продукта VPPL.
 */
export const getPublicationCode = (product: ProductType): ServiceItemCode | null => {
    const publicationProduct = findProduct(product, (subProduct) => publicationsCodes.includes(subProduct.code));
    return publicationProduct ? publicationProduct.code : null;
};
