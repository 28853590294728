import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Gap from 'bloko/blocks/gap/index';
import VSpacing from 'bloko/blocks/vSpacing';

import CONTENT_ID from 'src/components/A11y/A11yConstants';
import ChatikSpoilerPreloader from 'src/components/ChatikSpoiler/Preloader';
import SearchType from 'src/components/NovaFilters/SearchType';
import ResumeCard from 'src/components/ResumeCard';
import NotFoundByContactBanner from 'src/components/ResumeSearch/NotFoundByContactBanner';
import ResumeSearchBanners from 'src/components/ResumeSearch/ResumeSearchBanners';
import SearchRating from 'src/components/SearchRating';
import translation from 'src/components/translation';
import useSearchQualityUxFb from 'src/hooks/useSearchQualityUxFb';
import { useSelector } from 'src/hooks/useSelector';
import { resumeSearchResultUpdateResume } from 'src/models/search/resume/resumeSearchResult';

const FIRST_BANNER_POSITION = 3;
const SECOND_BANNER_POSITION = 9;
const DEFAULT_RATING_POSITION = 9;
const RESUME_SEARCH_RATING = 'resume_search_rating';

const SearchResults = ({ trls }) => {
    const dispatch = useDispatch();
    useSearchQualityUxFb(SearchType.Resume);

    const banners = useSelector((state) => state.banners);
    const resumes = useSelector((state) => state.resumeSearchResult.resumes);
    const withHiddenResumes = useSelector((state) => state.resumeSearchResult.withHiddenResumes);
    const features = useSelector((state) => state.features);
    const searchLoading = useSelector((state) => state.searchLoading);
    const [resumesNumber, setResumesNumber] = useState(
        features[SearchResults.features.numberOfResumesRenderedOnServer]
    );
    const videos = useSelector((state) => state.resumeSearchResult?.videos) ?? {};
    /**
     * Рендерим на сервере и на клиенте до CDM только минимально необходимое количество резюме.
     * Значение в настройке зависит от количества резюме выводимых в SEO-каталогах резюме.
     *
     * Это нужно потому, что рендер резюме — очень нагруженная операция,
     * из-за большого количества элементов и логики,
     * чтобы укладываться в адекватное время на сервере решили остановиться
     * на частичном рендере и таким образом оптимизировать время серверного рендеринга
     */
    const resumesToRender = !resumesNumber ? resumes : resumes.slice(0, resumesNumber);
    useEffect(() => {
        if (resumesNumber && resumes.length > resumesNumber) {
            // значение 0 отрендерит все
            setResumesNumber(0);
        }
    }, [resumes.length, resumesNumber]);

    if (resumes.length === 0 && withHiddenResumes) {
        return <NotFoundByContactBanner />;
    }

    if (resumes.length === 0) {
        return (
            <Gap top bottom>
                {trls[SearchResults.trls.notFound]}
            </Gap>
        );
    }

    const ratingPosition = Math.min(resumesToRender.length - 1, DEFAULT_RATING_POSITION);
    return (
        <main className="resume-serp-content" data-qa="resume-serp__results-search" id={CONTENT_ID}>
            {withHiddenResumes && (
                <>
                    <NotFoundByContactBanner />
                    <VSpacing base={4} />
                </>
            )}
            {resumesToRender.map((resume, index) => (
                <Fragment key={resume._attributes.hash}>
                    <ResumeCard
                        key={resume._attributes.hash}
                        resume={resume}
                        videoLink={videos[resume._attributes.user]}
                        onResumeUpdate={(resume) => dispatch(resumeSearchResultUpdateResume(resume))}
                    />
                    {[FIRST_BANNER_POSITION, SECOND_BANNER_POSITION].includes(index + 1) && (
                        <ResumeSearchBanners banners={banners} position={index + 1} />
                    )}
                    {ratingPosition === index && (
                        <SearchRating
                            portfolio="PORTFOLIO-16916"
                            eventName={RESUME_SEARCH_RATING}
                            infoTipName={RESUME_SEARCH_RATING}
                        />
                    )}
                </Fragment>
            ))}
            <ChatikSpoilerPreloader />
            {searchLoading && <div className="resume-serp-content-loader" />}
        </main>
    );
};

SearchResults.trls = {
    notFound: 'search.vacancy.changeParamsOrKeywords',
};

SearchResults.propTypes = {
    trls: PropTypes.object.isRequired,
};

SearchResults.features = {
    numberOfResumesRenderedOnServer: 'max_resumes_rendered_on_server',
};

export default translation(SearchResults);
