import { AnyAction, Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { emptyFiltersResult } from 'src/components/Notifications/EmployerVacancies';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { filterVacancies } from 'src/models/employerVacancies/managerVacancies';
import { VacanciesFilters, VacanciesList } from 'src/models/employerVacancies/vacancy.types';
import { PfpInfo, setPfpInfo } from 'src/models/payForPerformance/pfpInfo';
import fetcher from 'src/utils/fetcher';

type QueryParams = Partial<VacanciesFilters> & { groupId?: string; order?: string };

interface SearchParams {
    params: QueryParams;
    catchEmpty?: boolean;
}

declare global {
    interface FetcherGetApi {
        ['/shards/employer/vacancies_dashboard/vacancies']: {
            queryParams: QueryParams;
            response: {
                vacanciesData: VacanciesList;
                clickmeAutoCampaignPromotionEnabled: boolean;
                pfpInfo?: PfpInfo;
                showPfpBalanceWarning: boolean;
            };
        };
    }
}

const setShowPfpBalanceWarning = makeSetStoreField('showPfpBalanceWarning');

export default (
        { params, catchEmpty }: SearchParams,
        addNotification: AddNotification
    ): ((dispatch: Dispatch<AnyAction>) => Promise<boolean>) =>
    async (dispatch: Dispatch<AnyAction>) => {
        try {
            const { vacanciesData, pfpInfo, showPfpBalanceWarning } = await fetcher.get(
                '/shards/employer/vacancies_dashboard/vacancies',
                {
                    params,
                }
            );

            if (pfpInfo) {
                dispatch(setShowPfpBalanceWarning(showPfpBalanceWarning));
                dispatch(setPfpInfo(pfpInfo));
            }

            if (catchEmpty && vacanciesData.activeVacanciesCount === 0) {
                addNotification(emptyFiltersResult);

                return true;
            }

            dispatch(filterVacancies({ vacancies: vacanciesData }));

            return false;
        } catch (err) {
            defaultRequestErrorHandler(err, addNotification);

            throw err;
        }
    };
