import useExperiment from 'src/hooks/useExperiment';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

export const useSmallResponseButtonExp = (): boolean => {
    const isHhPlatform = useIsHeadHunterPlatform();

    const userType = useSelector(({ userType }) => userType);
    const isEmployer = userType === UserType.Employer;

    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);

    const callHook = isHhPlatform && !isEmployer && !isMapVacancySearch;

    return useExperiment('desktop_web_response_button', callHook);
};
