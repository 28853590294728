import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonKind, ButtonScale, ButtonType } from 'bloko/blocks/button';
import { H3Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { showPickVacancyModal } from 'src/models/pickVacancyOnResumeSearch';

import styles from './style.less';

const TrlKeys = {
    title: 'resumeSearch.pickVacancyBanner.title',
    description: 'resumeSearch.pickVacancyBanner.description',
    buttonText: 'resumeSearch.pickVacancyBanner.buttonText',
};

const PickVacancyBanner: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(showPickVacancyModal(true));
    }, [dispatch]);

    return (
        <div className={styles.banner}>
            <div className={styles.bannerInfo}>
                <H3Section>{trls[TrlKeys.title]}</H3Section>
                <VSpacing base={2} />
                <div className={styles.bannerDescriptionContainer}>
                    <Text>{trls[TrlKeys.description]}</Text>
                </div>
                <VSpacing base={2} />
                <Button
                    kind={ButtonKind.Primary}
                    type={ButtonType.Button}
                    scale={ButtonScale.Small}
                    onClick={openModal}
                >
                    {trls[TrlKeys.buttonText]}
                </Button>
            </div>
            <div className={styles.bannerImage} />
            <div className={styles.bannerActionCard} />
        </div>
    );
};

export default translation(PickVacancyBanner);
