import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

export const useShowPickVacancyAdditionalHeader = (): boolean => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = userType === UserType.Employer;

    const { isBannerShow, isEnablePickVacancy } = useSelector((state) => state.pickVacancyOnResumeSearch);

    return isEmployer && !!isEnablePickVacancy && !isBannerShow;
};
