import { useEffect } from 'react';

import SearchType from 'src/components/NovaFilters/SearchType';
import { useSmallResponseButtonExp } from 'src/components/VacancySearchItem/hooks/useSmallResponseButtonExp';
import { UserType } from 'src/models/userType';
import { UXFeedback } from 'src/utils/uxfeedback';

import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';

const excludingUserType = {
    [SearchType.Resume]: UserType.Applicant,
    [SearchType.Vacancy]: UserType.Employer,
};

export default (searchType: SearchType.Resume | SearchType.Vacancy): void => {
    const isMagritte = useMagritte();
    const isRabotaBy = useIsRabotaBy();
    const userType = useSelector((state) => state.userType);
    const searchRating = useSelector((state) => state.searchRating);
    const showSearchQualityUxFb = !isRabotaBy && !searchRating && userType !== excludingUserType[searchType];
    const { enableSimilarSavedSearch } = useSelector((state) => state.vacancySearchResult);
    const isSmallResponseButtonExp = useSmallResponseButtonExp();
    useEffect(() => {
        if (!showSearchQualityUxFb) {
            return;
        }
        /* Possible values:
         * Resume search:
         * - resume_search_quality_anonymous
         * - resume_search_quality_employer
         * Vacancy search:
         * - vacancy_search_quality_anonymous
         * - vacancy_search_quality_applicant
         * - recommended_resume_search */
        let eventName = `${searchType}_search_quality_${userType}`;
        if (enableSimilarSavedSearch) {
            eventName = 'recommended_resume_search';
        }
        /* Possible values
         * Resume search:
         *  - resume_search_quality_anonymous_magritte
         *  - resume_search_quality_employer_magritte
         * Vacancy search:
         *  - vacancy_search_quality_anonymous_magritte
         *  - vacancy_search_quality_applicant_magritte
         *  - recommended_resume_search_magritte */
        if (isMagritte && searchType !== SearchType.Vacancy) {
            eventName = `${eventName}_magritte`;
        }

        if (searchType === SearchType.Vacancy && isSmallResponseButtonExp) {
            eventName = `${eventName}_response-exp`;
        }
        UXFeedback.sendEvent(eventName);
    }, [
        userType,
        showSearchQualityUxFb,
        searchType,
        enableSimilarSavedSearch,
        isMagritte,
        isSmallResponseButtonExp,
        isRabotaBy,
        searchRating,
    ]);
};
