import React from 'react';

import CustomSelect, { CustomSelectOption } from 'bloko/blocks/customSelect';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey, OrderByOption } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    [OrderByOption.PublicationTime]: 'search.order.publication_time',
    [OrderByOption.SalaryDesc]: 'search.order.salary_desc',
    [OrderByOption.SalaryAsc]: 'search.order.salary_asc',
    [OrderByOption.Relevance]: 'search.order.relevance',
};

interface SearchOrderProps {
    onChange: (value: OrderByOption) => void;
    value: OrderByOption;
}

const SearchOrder: TranslatedComponent<SearchOrderProps> = ({ onChange, value, trls }) => {
    const values = useSelector((state) => state.resumeSearchDictionaries[CriteriaKey.OrderBy]);
    if (!values) {
        return null;
    }

    return (
        <CustomSelect
            name="order_by"
            onChange={onChange}
            value={value}
            wrapperProps={{ 'data-qa': 'serp-settings__order-by' }}
            light
            flexible
        >
            {values.map((value) => (
                <CustomSelectOption data-qa={`order-by-${value}`} key={value} value={value}>
                    {trls[TrlKeys[value]]}
                </CustomSelectOption>
            ))}
        </CustomSelect>
    );
};

export default translation(SearchOrder);
