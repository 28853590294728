import { CurrencyType } from 'src/models/currencies.types';

import { HhTariff } from 'src/models/price/hhTariff';
import { ServiceCode, ServiceItemCode } from 'src/models/price/productCodes';
import { ZpTariff } from 'src/models/price/zpTariff';

export const ANY_REGION_ID = '0';
export const ANY_PROF_ROLE_GROUP_ID = '0';
export const START_AFTER_PAYMENT = '';

export type CountType = string; // Должен быть number
export type ProductIdType = string;
export type RegionIdType = string;
export type PeriodType = number;
export type WrongPeriodType = string; // Должен быть number
export type StartAfterPayment = typeof START_AFTER_PAYMENT;

export enum BooleanString {
    True = 'true',
    False = 'false',
}

export enum IntervalInSecondsString {
    OneDay = '1440',
    ThreeDays = '4320',
    SevenDays = '10080',
}

export enum IntervalInDaysString {
    OneWeek = '7',
    OneYear = '365',
}

export enum BillingPlatform {
    HH = 'HH',
    ZP = 'ZP',
}

export interface ResumeAccessFeatures {
    /**
     * Открытые контакты сохранятся на 365 дней
     */
    civDuration?: IntervalInDaysString;
    billingPlatforms?: BillingPlatform[];
}

export interface PublicationFeatures {
    /**
     * Поднятие на первые страницы поиска раз в 24 часа вручную
     */
    manualUpdateInterval: IntervalInSecondsString;
    /**
     * Автоподнятие на первые страницы поиска раз в 3 дня
     */
    autoUpdateInterval: IntervalInSecondsString;
    /**
     * Выделение логотипом
     */
    logo: BooleanString;
}

export interface OptionFeatures {
    /**
     * На первых местах в поиске
     */
    searchPremiumPosition: IntervalInDaysString;
    /**
     * Реклама на главной в разделе «Вакансии дня»
     */
    vacancyDay: IntervalInDaysString;
    /**
     * Выделение цветным значком
     */
    premiumLabel: BooleanString;
    /**
     * Выделение логотипом
     */
    logo: BooleanString;
    /**
     * Поднятие на первые страницы поиска раз в 24 часа вручную
     */
    manualUpdateInterval: IntervalInSecondsString;
}

export interface SubProductType {
    code: ServiceItemCode;
    count: CountType;
    features?: ResumeAccessFeatures | PublicationFeatures | OptionFeatures;
    description?: {
        SHORT?: {
            title: string;
        };
    };
    profRoleGroupTrls?: string[];
    regionTrl?: string;
}

export interface ResumeAccessSubProductType extends SubProductType {
    code: ServiceItemCode.ContactsView;
    features?: ResumeAccessFeatures;
}

export interface ProductType {
    businessContext?: {
        auction?: unknown;
    };
    count: CountType;
    code: ServiceCode | ServiceItemCode;
    currency: CurrencyType;
    price: number;
    products?: Record<string, SubProductType>;
    profRoleGroup: string[];
    region: string;
    tariff?: ZpTariff | HhTariff;
    /** Последний день действия цены: YYYY-MM-DD */
    priceValidUntilDate?: string;
    description?: {
        SHORT?: {
            title: string;
        };
    };
    productId?: number;
    productTreeNodeId?: number;
    seller: BillingPlatform;
}

export interface TariffProductType extends ProductType {
    period: string;
    tariff: ZpTariff;
}

export interface AddContactsProductType extends ProductType {
    code: ServiceCode.Add | ServiceCode.Reservation;
    products: Record<string, SubProductType>;
}

export interface BrandingProductType extends ProductType {
    code: ServiceCode.BrandingBuilderVacancy | ServiceCode.BrandingBuilderPage;
    period: string;
    products: Record<string, SubProductType>;
}

export interface ClickmeProductType extends ProductType {
    code: ServiceItemCode.Clickme;
}

export interface DBAccessProductType extends ProductType {
    code: ServiceCode.Disposable | ServiceCode.ContactsViewWithStandart;
    period: string;
    products: Record<string, SubProductType>;
}

export interface PublicationProductType extends ProductType {
    code: ServiceCode.LimitedVacancy;
    period: string;
    pricePerOne: number;
    netPricePerOne: number;
    zpCrossPostingPricePerOne: number;
    // tempexp_33278_next_line
    priceGroupId: string;
}

export interface PfpProductType extends ProductType {
    code: ServiceCode.Reservation;
    period: string;
    pricePerOne: number;
    priceGroupId: string;
}
