import { FormEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import Button, { ButtonType } from 'bloko/blocks/button';
import Checkbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { SettingName, SettingType } from 'src/models/search/resume/resumeSearchResult';
import setResumeSearchSettings from 'src/utils/employer/setResumeSearchSettings';

interface SettingsFormProps {
    close?: () => void;
}

const TrlKeys = {
    settingName: {
        [SettingName.ShowFiltersOnTheLeft]: 'resume.search.field.show_filters_on_the_left',
    },
    settingType: {
        [SettingType.LastChangeTime]: 'resume.search.field.last_change_time',
        [SettingType.Name]: 'resume.search.field.name',
        [SettingType.Photo]: 'resume.search.field.photo',
        [SettingType.LastExperience]: 'resume.search.field.last_experience',
        [SettingType.Comments]: 'resume.search.field.comments',
        [SettingType.CommentsExpanded]: 'resume.search.field.comments_expanded',
        [SettingType.Languages]: 'resumeCard.field.languages',
        [SettingType.Area]: 'resume.search.field.area',
        [SettingType.Education]: 'resumeCard.field.education',
        [SettingType.Citizenship]: 'resume.search.field.citizenship',
        [SettingType.Companies]: 'resumeCard.field.experience',
        [SettingType.Relocation]: 'resume.search.field.relocation',
        [SettingType.Certificates]: 'resumeCard.field.certificates',
        [SettingType.ExperienceSum]: 'resume.search.field.experience_sum',
        [SettingType.ProfessionalRole]: 'resume.search.field.professional_role',
        [SettingType.RoleWarring]: 'resume.search.field.roleWarring',
    },
    save: 'Save',
};

const SettingsForm: TranslatedComponent<SettingsFormProps> = ({ close, trls }) => {
    const { activeSettings, availableSettings, filtersOnTheLeft } = useSelector(
        (state) => state.resumeSearchResult.settings
    );
    const formElement = useRef<HTMLFormElement>(null);
    const [isFetching, setIsFetching] = useState(false);
    const { pathname, search } = useSelector((state) => state.router.location);
    const dispatch = useDispatch();

    const [isFiltersOnTheLeftToggled, setFiltersOnTheLeftToggled] = useState(false);

    if (!availableSettings || !activeSettings) {
        return null;
    }

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!formElement.current) {
            return;
        }
        setIsFetching(true);
        try {
            await setResumeSearchSettings(new FormData(formElement.current));
        } catch (e) {
            console.error(e);
        } finally {
            close?.();
            dispatch(push(`${pathname}${search}`));
        }
    };

    return (
        <Text size={TextSize.Small} data-qa="resume-serp__set-view-result-block">
            <Form onSubmit={submit} ref={formElement} data-qa="resume-serp__view-form">
                <ul>
                    {availableSettings.length !== 0 &&
                        availableSettings.map((field) => (
                            <FormItem key={field}>
                                <Checkbox
                                    defaultChecked={activeSettings.includes(field)}
                                    data-qa={`resume-serp__view-${field}`}
                                    name="field"
                                    value={field}
                                >
                                    <span className="resume-serp-filters-form__text">
                                        {trls[TrlKeys.settingType[field]]}
                                    </span>
                                </Checkbox>
                            </FormItem>
                        ))}
                    {filtersOnTheLeft && (
                        <>
                            <div className="resume-serp-filters-divider">
                                <div className="horizontal-divider" />
                            </div>
                            <input
                                type="hidden"
                                name={`cs-${filtersOnTheLeft.name}`}
                                value={String(isFiltersOnTheLeftToggled)}
                            />
                            <FormItem>
                                <Checkbox
                                    defaultChecked={filtersOnTheLeft.value}
                                    data-qa={`resume-serp__view-${filtersOnTheLeft.name}`}
                                    name={filtersOnTheLeft.name}
                                    value="true"
                                    onChange={() => setFiltersOnTheLeftToggled(true)}
                                >
                                    <span className="resume-serp-filters-form__text">
                                        {trls[TrlKeys.settingName[filtersOnTheLeft.name]]}
                                    </span>
                                </Checkbox>
                            </FormItem>
                        </>
                    )}
                </ul>
                <div className="resume-serp-filters-save">
                    <Button
                        type={ButtonType.Submit}
                        data-qa="resume-serp__view-save"
                        loading={isFetching && <Loading scale={LoadingScale.Small} />}
                    >
                        {trls[TrlKeys.save]}
                    </Button>
                </div>
            </Form>
        </Text>
    );
};

export default translation(SettingsForm);
